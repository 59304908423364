<template>
	<div v-show="!showChild">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="规则名称" name="name">
						<a-input v-model:value="formState.name" placeholder="请输入规则名称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="状态" name="isDisabled">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" style="width: 200px;"></a-select>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['performance_order_refundRules_add']" type="primary" @click="onAddTag">新增售后规则</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'image'">
							<a-image :src="record.icon"></a-image>
						</template>
						<template v-if="column.key === 'isDisabled'">
							<div>
								{{ record.isDisabled ? '已禁用' : '已启用' }}
							</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['performance_order_refundRules_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['performance_order_refundRules_disabled']" v-if="record.isDisabled" @click="onChangeState(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['performance_order_refundRules_disabled']" v-else @click="onChangeState(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['performance_order_refundRules_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
	<detail v-if="showChild" :id="id" @back="onBack" :isEdit="isEdit"></detail>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import detail from './detail.vue';
	import { getPerformRefundRulesList, deletePerformRefundRules, disabledPerformRefundRules } from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			detail
		},
		data() {
			return {
				isEdit: false,
				id: 0,
				loading: false,
				searchData: {},
				showChild: false,
				modelRef: {},
				formState: {},
				list: [],
				columns: [{
					title: '规则名称',
					dataIndex: 'name',
				}, {
					title: '状态',
					key: 'isDisabled',
				}, {
					title: '操作',
					key: 'action',
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showChild = false;
				if (isRef) {
					this.getData();
				}
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getPerformRefundRulesList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			onAddTag() {
				this.id = 0;
				this.isEdit = false;
				this.showChild = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.showChild = true;
			},
			onSubmit() {
				this.showChild = false
				this.modelRef = {}
			},
			onCancel() {
				this.modelRef = {}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePerformRefundRules({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功！');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onChangeState(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.isDisabled ? '启用' : '禁用' }该选项吗？`,
					onOk: async ()=> {
						this.loading = true;
						let ret = await disabledPerformRefundRules({
							id: item.id,
							isDisabled: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ item.isDisabled ? '启用' : '禁用' }成功！`);
							this.getData();
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>